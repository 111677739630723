/*Header */

.header {
    padding: 40px 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--page-bg);
    background-image: var(--header-bg-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 0;
    color: var(--project-card-text);
    text-align: center;
}
.header:after {
    content: '';
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--shadow-bg);
    z-index: -1;
}

.header__wrapper {
    padding: 0px 15px;
    max-width: 660px;
}

.header__title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
}

.header__tools {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
}

.header__title strong {
    font-size: 40px;
    font-weight: 700;
    color: var(--project-card-text);
}

.header__text {
    margin-bottom: 40px;
    font-size: larger;
    font-weight: bolder;
    line-height: 1.333;
}

.header__text p+p {
    margin-top: 0.5em;

}