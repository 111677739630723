/* Dark mode btn */

.dark-mode-btn {
    order: 4;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 46px;
    height: 23px;
    padding: 2.8px 4px;
    border-radius: 2px;
}

.dark-mode-btn::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    display: block;
    width: 21px;
    height: 21px;
    border-radius: 2px;
    background-color: var(--active);
    transition: left 0.2s ease-in;

}

.dark-mode-btn--active::before {
    left: 26px;

}

.dark-mode-btn__icon {
    position: relative;
    z-index: 9;
    width: 14px;
    height: 16px;
    align-items: center;
}

.sun {
    fill: white;
}

.moon {
    fill: black;
}