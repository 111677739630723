/* Nav */
/* .nav {
    padding: 10px 0;
    border-bottom: 1px solid var(--dark-border);
    background-color: var(--nav-bg);
    color: var(--header-text);
    letter-spacing: normal;
    position: fixed;
    width: 100%;
    z-index: 1;
} */

/* .nav-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    row-gap: 20px;
    flex-wrap: wrap;
} */

.logo {
    margin-right: auto;
    color: var(--project-card-text);
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    order: 1;
}

.logo strong {
    font-weight: 700;
}

/* .nav-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    order: 2;
    width: 400px;
    justify-content: space-around;
} */

.nav-list__link {
    color: var(--header-text);
    transition: opacity 0.2s ease-in;
    padding: 1.6px 4px;
    border-radius: 2px;
}

.nav-list__link:hover {
    opacity: 0.8;
}

.nav-list__link--active {
    position: relative;
    font-weight: bold;
    color: var(--accent);
    background-color: var(--active);
}

/* test */
.navbar {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 120px;
    background-color: var(--nav-bg);
    box-shadow: var(--box-shadow-nav);
    color: var(--header-text);
    z-index: 2;
}

.navbar__logo {
    color: var(--project-card-text);
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
}

.navbar__nav {
    margin-left: auto;
}

.navbar__nav-list {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    justify-content: space-around;
}

.navbar__nav-item {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    line-height: 1;
}

.navbar__nav-item:nth-child(n + 2) {
    margin-left: 20px;
}

.navbar__menu-button,
.navbar__menu-close {
    display: none;
}

@media (max-width: 1000px) {
    .navbar {
        padding: 10px 22px;
    }
}

@media (max-width: 760px) {
    .navbar__nav {
        position: fixed;
        top: 0;
        right: -235px;
        display: flex;
        flex-direction: column;
        width: 220px;
        height: 100%;
        background-color: var(--menu-bg);
        box-shadow: var(--box-shadow-menu);
        transition: transform 0.3s linear;
    }

    .navbar__nav.active {
        transform: translateX(-105%);
    }

    .navbar__nav-list {
        flex: 0;
        display: flex;
        flex-direction: column;
        padding-top: 60px;
    }

    .navbar__nav-item {
        margin: 10px 0;
        font-weight: 500;
    }

    .navbar__nav-item:nth-child(n + 2) {
        margin-left: 0;
    }

    .navbar__menu-button {
        display: block;
        margin-left: auto;
        width: 24px;
        height: 24px;
        background-color: transparent;
        padding: 0;
        z-index: 0;
    }

        .button-icon--active {
            fill: rgb(228, 228, 228);
        }

    .navbar__menu-close {
        display: block;
        margin-left: auto;
        padding: 12px 24px 0 0;
    }

    .button-icon {
        width: 24px;
        height: 24px;
        fill: var(--header-text);
    }

}