@import "./reset.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');
/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons"); */

/*
font-family: 'DM Sans', sans-serif;
font-family: 'Poppins', sans-serif;
*/

/* * {
    border: 1px solid red;
} */

:root {
    --tw-bg-opacity: 1;
    --blackpure: #000;
    --black: rgb(0 54 82/var(--tw-bg-opacity));
    --black-transparent: #171718;
    --black-border: #26292D;
    --gray: rgba(255, 255, 255, 0.88);
    --gray-black: #0000005a;
    --white: #e6e6e6fc;
    --super-white: white;
    --blue: rgb(97, 217, 250);
    --grey: rgb(228, 228, 228);
    /* --purple: rgb(23, 130, 108); */
    --purple: rgb(1, 147, 145);
    --light: rgba(216, 216, 216, 0.309);
    --dark: rgba(0, 0, 0, 0.346);
    --text-color: var(--black);
    --dark-bg: var(--white);
    --dark-border: var(--black-border);
    --header-text: var(--black);
    --accent: var(--super-white);
    --active: var(--purple);
    --shadow-bg: var(--light);
    /* Light mode */
    --sun: var(--black);
    --moon: var(--white);
    --nav-bg: var(--grey);
    --menu-bg: rgba(242, 242, 242, 0.936);
    --page-bg: var(--white);
    --text-color: var(--black);
    --header-bg-image: url('./../img/service2.png');
    --title-1: var(--accent);
    --project-card-bg: var(--gray);
    --project-card-text: var(--blackpure);
    --box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
    --box-shadow-norm: 0px 5px 10px rgba(0, 0, 0, 0.25);
    --box-shadow-menu: 0 0 10px 6px rgba(0, 0, 0, 0.308);
    --box-shadow-nav: 0px 5px 25px rgba(1, 149, 146, 0.635);
}

.dark {
    /* Dark mode */
    --sun: var(--white);
    --moon: var(--black);
    --page-bg: #3e3e44;
    --text-color: var(--blue);
    --header-text: var(--white);
    --shadow-bg: var(--dark);
    --title-1: var(--white);
    --active: var(--blue);
    --header-bg-image: url('./../img/service.png');
    --dark-bg: var(--black);
    --accent: var(--blackpure);
    --nav-bg: var(--black-transparent);
    --menu-bg: rgba(0, 0, 0, 0.875);
    --project-card-bg: var(--gray-black);
    --project-card-text: var(--white);
    --box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
    --box-shadow-norm: 0px 5px 10px rgba(0, 0, 0, 0.8);
    --box-shadow-menu: 0 0 10px 8px rgba(0, 0, 0, 0.483);
    --box-shadow-nav: 0px 5px 25px rgb(168, 227, 244);
}

html,
body {
    min-height: 100vh;
    font-family: 'DM Sans', sans-serif;
    letter-spacing: -0.5px;
    /* Light mode */
    background-color: var(--page-bg);
    color: var(--header-text);
}


#root {
    min-height: 100vh;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* General */

.container {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1200px;
}

.none {
    display: none !important;
}

.section {
    padding: 60px 0;
    background-color: var(--page-bg);
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
}

.title-1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.3;
    color: var(--project-card-text);
    text-align: center;
}

.title-2 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.3;
}

.title-3 {
    margin: 20px 20px 20px 20px;
    font-size: 24px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Btn */

.btn {
    display: inline-block;
    height: 38px;
    padding: 10px 12px;
    border-radius: 5px;
    background-color: var(--accent);
    color: var(--white);
    letter-spacing: 0.15px;
    font-size: 14px;
    font-weight: 100;
    transition: opacity 0.2s ease-in;
}

.btn:hover {
    opacity: 0.8;
}

.btn:active {
    position: relative;
    top: 1px;
}

/* Projects  */

.projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
    opacity: 1;
    margin-bottom: 30px;
}

/* Project details */

.project-details {
    margin: 0 auto;
    max-width: 60vw;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 0;
}

.project-details__cover {
    max-width: 100%;
    margin-bottom: 40px;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.project-details__desc {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
}

/* Content list */

.contact {
    margin: 0 auto;
    max-width: 570px;
    background-color: var(--project-card-bg);
    backdrop-filter: blur(10px) contrast(50%);
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 20px;
}

.content-list {
    margin: 0 auto;
    max-width: 570px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    text-align: center;
}

.content-list a {
    color: var(--header-text);
}

.content-list__item {
    font-size: 18px;
    line-height: 1.5;
}

.content-list__item p+p {
    margin-top: 0.5em;
}

.section__reviews {
    padding: 0;
    background-color: var(--page-bg);
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
}

.reviews__avito {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



.loading {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: #3e3e44;
}

.loading-img {
    height: auto;
    width: 20px;
    animation: slideRight 4s linear forwards;
}

@keyframes slideRight {
    0% {
        transform: translateX(-100vw);
    }

    25% {
        transform: translateX(-75vw);
        width: 100px;

    }

    50% {
        transform: translateX(0);
        width: 150px;
    }



    100% {
        transform: translateX(0);
        width: 200px;
    }
}

@media (max-width: 1200px) {
    li.project {
        min-width: 40vw;
        padding-top: 10px;
    }

    div.main-container {
        padding: 20px;
        align-items: center;
    }
}


@media (max-width: 840px) {
    .title-1 {
        margin-top: 2vh;
        font-size: 22px;
        margin-bottom: 2vh;
    }

    h1.title-1 {
        margin-top: 4vh;
    }

    .header.header {
        background-size: cover;
    }

    /* ul.nav-list {
        width: 360px;
    } */

    a.logo {
        font-size: 20px;
        margin-right: auto;
    }
}

@media (max-width: 780px) {
    h2.title-1 {
        margin-top: 4vh;
    }

    /* div.nav-row {
        row-gap: 10px;
    } */

    a.logo {
        font-size: 20px;
        margin-right: auto;
    }

    .project-details {
        max-height: 96vh;
    }
}

@media (max-width: 620px) {

    .logo.active {
        order: 2;
    }


    .header {
        min-height: unset;
        flex-direction: column;

    }

    .header__wrapper {
        margin-top: 0px;
    }

    h1.header__title {
        padding-top: 2vh;
    }

    /* Nav */


    /* ul.nav-list {
        font-size: 14px;
        order: 4;
    } */

    /* div.nav-row {
        justify-content: space-evenly;
        row-gap: 18px;
    } */

    .btn {
        height: 26px;
        font-size: 12px;
        font-weight: 100;
        padding: 4px 8px;

    }

    .dark-mode-btn {
        order: 3;
    }

    .btn_lang {
        order: 1;
    }

    /* General */

    .section {
        padding: 40px 0;
    }

    h2.title-1 {
        margin-top: 8vh;
        font-size: 28px;
        margin-bottom: 2vh;
    }

    h1.title-1 {
        margin-top: 8vh;
    }

    .title-2 {
        margin-bottom: 10px;
        font-size: 20px;
    }

    /* Projects */

    div.main-container {
        padding: 0px;
        align-items: center;
    }

    ul.projects {
        margin-top: 2vh;
        margin-bottom: 10vh;
    }

    li.project {
        min-width: 80vw;
        padding-top: 10px;
    }


    h3.project__title {
        font-size: 16px;
        height: auto;
    }

    /* Project page */

    .project-details__desc {
        margin-bottom: 20px;
        font-size: 22px;
    }

    .project-details {
        max-width: 96vw;
    }

    /* Content List */

    .content-list {
        row-gap: 10px;
        margin-bottom: 10px;
    }

    .content-list__item {
        font-size: 16px;
    }

    div.contact {
        margin-bottom: 10px;
    }

    /* Footer */

    .footer {
        padding: 40px 0 30px;
    }

    .footer__wrapper {
        row-gap: 20px;
    }

    /* Social */

    .social {
        column-gap: 20px;
    }

    .social__item {
        width: 28px;
    }

}

@media (max-width: 380px) {
    .section__reviews {
        margin: 50px 0;
    }
}

@media (max-width: 320px) {
    .section__reviews {
        margin: 50px 0 14px 0;
    }

}

@media (max-width: 330px) {

    h1.header__title strong {
        font-size: 28px;
    }

    h2.title-1 {
        margin-top: 10vh;
    }

    h1.title-1 {
        margin-top: 10vh;
    }

    /* ul.nav-list {
        column-gap: 2px;
    }

    div.nav-row {
        row-gap: 16px;
    } */

    /* .nav {
        font-weight: lighter;
        font-size: small;
    } */

    div.contact {
        margin-bottom: 10px;
    }

    li.project {
        max-width: 80vw;
    }

    .container {
        padding: 0 6px;
    }

    .header__wrapper {
        margin-top: 42px;
    }
}

@media (max-height: 480px) {

    .section__reviews {

        min-height: calc(100vh + 400px);
    }

    .reviews__avito {
        margin-top: 50px;
    }
}