/* Footer */

.footer {
    margin-top: auto;
    padding: 8px 0;
    background-color: var(--nav-bg);
    color: var(--header-text);
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index:1;
}

.footer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

}


.social {
    display: flex;
    column-gap: 36px;
}

li.social__item {
    width:22px;
    align-items: center;
}

.social_icon {
    width: 22px;
    fill: var(--header-text);
    vertical-align: middle;
}

.copyright {
    font-size: 16px;
}

.copyright p+p {
    margin-top: 0.5em;
}

 .reviews__menu-button {
     display: flex;
     align-self: center;
     width: 60px;
     height: 24px;
     background-color: transparent;
     color: var(--header-text);
     z-index: 0;
 }

 .reviews__menu-button--active {
    color: var(--active);
 }