.main-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 60px;
}

.window {
    height: 100%;
    width: 100%;
    overflow: hidden;
    align-items: center;


}

.all-pages {
    height: 100%;
    display: flex;
    transition: translate;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    align-items: center;

}

.arrow {
    cursor: pointer;
    width: 30px;
}