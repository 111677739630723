.btn_lang {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    font-size: smaller;
    border-radius: 2px;
    font-weight: normal;
    order: 3;
}

.lang {
    /* padding: 2.2px 4px; */
    padding: 0.7px 4px;
    border-radius: 2px;
}

.selected {
    font-weight: bold;
    /* Change the background color to your desired color */
    color: var(--accent);
    background-color: var(--active);
}